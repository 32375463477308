import * as config from '../config';
import { sendRequest } from './restClient';

const query = ({ uri, token, next, verbose }) => {

    let params = {
        url: config.gateway + '/' + uri,
        callback: next,
        token: token,
        verbose: verbose
    }


    let success = sendRequest(params);

    return success;

}

const save = ({ uri, data, token, next, verbose }) => {

    let params = {
        data: data,
        method: 'post',
        url: config.gateway + '/' + uri,
        callback: next,
        token: token,
        verbose: false
    }

    let success = sendRequest(params);

    return success;

}



export const customize = ({ data, token, next, verbose }) => {

    const callbackTasks = (outcome) => {

        if (verbose) {
            //console.info("Customized", outcome);
        }

        if (next) {
            next(data);
        }
    }

    let success = save({
        data: data,
        token: token,
        next: callbackTasks,
        uri: 'customize',
        verbose: false
    });

    return success;

}

export const getData = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            //console.info("Downloaded", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'data',
        verbose: false
    });

    return success;

}



export const refreshReports = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            //console.info("Refreshed", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'reports',
        verbose: false
    });

    return success;

}

export const getLead = ({ id, next }) => {
    next({});
}

export const getSettings = (next) => {

    let settings = {
        asOf: 0,
        api: [
            { id: "Main website", ApiKey: "1234" }
        ],
        assets: { default: false },
        brands: [
            "live-belowcost-app.link-nonprod.wawanesalife.com"
        ],
        closing: {
            objections: {
                price: [
                    { id: "LgBbLUNgnp_GdHwhjxbnm", text: "Today's price is our best price" },
                    { id: "gciQRsfHnorysXmkwsXNC", text: "Life insurance is about value, not price" },
                    { id: "0x0Iqy4K5yl0HMMbX6919", text: "Can you put a price on peace of mind?" }
                ],
                value: [
                    { id: "SLyIaoQ4mw0Xm7gBzQEBi", text: "Wawanesa has been in business since 1896 and is in financial good health." },
                    { id: "QMkga-kq-YD5paigUkI_h", text: "Wawanesa is a mutual, which means it's own by the policyholders themselves." },
                    { id: "4Kf5RmM7OrS9xYXwGip3z", text: "Wawanesa has a $2 million annual community impact program called Wawanesa Climate Champions." }
                ],
                timing: [
                    { id: "LgBbLUNgnp_GdHwhjxbnm", text: "Don't wait - the premium for the same coverage next year will be much higher" },
                    { id: "AJnFIl5ct4YpcTCf98I5-", text: "The peace of mind you are seeking could start today." },
                    { id: "CssYfw6YxgkenG7w-xJmG", text: "If you develop medical conditions, lose your driver license or experience other hardships before buying a policy, you could lose your eligibility." },
                    { id: "XrbX_yXAE5mVRErBkmXTm", text: "If you don't expect big changes in your circumstances, waiting only delays your protection and makes it more expensive." }
                ],
                product: [
                    { id: "ilho5VM3Uw2EL9AaLIdHx", text: "A term insurance policy is less expensive and easier to renew than whole life insurance." },
                    { id: "u3cBPw58bhlnGO_IL3oGe", text: "Benefits are paid as a tax-free lump sum." },
                    { id: "E5xgPsAgJroPdBKnI7Kbn", text: "Group insurance only lasts while you are part of the group." }
                ]
            },
            tips: [{ id: "tv1EaPGSv6mU64tcKI9bB", author: "Grant Cardone", quote: "Treat all objections as complaints until validated." },
            { id: "BlE0r6kxRVgK8Q1MNfs7I", author: "Grant Cardone", quote: "Never disagree with the buyer." },
            { id: "tv1EaPGSv6mU64tcKI9bB", author: "Grant Cardone", quote: "Never talk while the buyer objects." },
            { id: "Z1HvTcJa3VhBsCj8zghuP", author: "Grant Cardone", quote: "Never blame the customer or other conditions for the objection." },
            { id: "ncNVy2JZ5_hTG7mbeDJ0H", author: "Grant Cardone", quote: "Never let the buyer get into a no-win situation." },
            { id: "Q-be16txjiJoT_02ChpZw", author: "Jill Konrath", quote: "Insightful questions build credibility and deepen relationships" },
            { id: "rmP3zRKms9i2luem95dmk", author: "Anthony Iannarino", quote: "Time has never helped anyone make a better decision. They need more information and better counsel." },
            { id: "LCsmkzphIs8diDVWR_yI7", author: "Anthony Iannarino", quote: "More clients are lost to neglect than to any other cause." }
            ]
        },
        contact: {
            default: {},
            region1: {}
        },
        analytics: [
            { id: "Google Analytics", A: "1234", B: "1234" }
        ],
        happyPath: false,
        labels: [
            { id: "Ending (disqualified)", A: "We have identified products that are a better match for you.", B: "We have identified products that are a better match for you." },
            { id: "Ending (product mismatch)", A: "We have identified products that are a better match for you.", B: "We have identified products that are a better match for you." }
        ],
        fields: [
            { id: "first_name", type: "String", description: "First name" },
            { id: "last_name", type: "String", description: "Last name" },
            { id: "email", type: "String", description: "Email" },
            { id: "phone", type: "String", description: "Phone" }
        ],
        notifications: false,
        profiles: false,
        urls: [
            { id: "Accessibility", A: "https://example.com", B: "https://example.com" },
            { id: "FTC", A: "https://example.com", B: "https://example.com" },
            { id: "Privacy", A: "https://example.com", B: "https://example.com" }
        ],
        users: {
            notifications: [{ id: "123", email: "jlmartel@wawanesa.com", verificationCode: "0097" }],
            system: [{ id: "123", email: "jlmartel@wawanesa.com" }]
        },
        video: [
            { id: "YouTube identifier", A: "1234", B: "1234" }
        ]
    };
    if (next) {
        next(settings);
    }

}

