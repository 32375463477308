

export const convertOffers = ({ valueStr }) => {

    // we convert the weird Athena format to an object

    let fields = ['norider', 'nonsmoker', 'term10', 'term20', 'smoker', 'rider', 'monthly', 'proposed', 'recommended'];
    let value = valueStr.replaceAll('=', ':');
    for (let field of fields) {
        value = value.replaceAll(field, '"' + field + '"');
    }
    value = value.replaceAll('"no"', '"no').replaceAll('"non"', '"non').replaceAll('""', '"');
    let offers = JSON.parse(value);

    return offers;

}