import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Card,
  CardGroup,
  Col,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  Row,
} from 'react-bootstrap';
import { FaRobot } from 'react-icons/fa';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from 'react-leaflet';

export const InteractiveMap = () => {

    const [items, setItems] = useState([]);
    const [ready, setReady] = useState(false);
    const [displayed, setDisplayed] = useState(false);

    useEffect(() => {


        let newItems = [
            { geo_latitude: 51.045642, geo_longitude: -114.188779, geo_city: 'Calgary', quote: "test1", group_name: "leads" },
            { geo_latitude: 46.103608, geo_longitude: -64.827552, geo_city: 'Moncton', quote: "test2", group_name: "leads" }
        ];
        setItems(newItems);
        console.info(newItems[0]);
        setReady(true);

    }, []);

    useEffect(() => {

        if (!displayed && items.length > 0) {

            setDisplayed(true);

        }

    }, [displayed, items]);


    //CustomMarkers

    const CustomMarkers = () => {
        const map = useMap();
        return items.map((el, i) => (
            <>
                {"leads" === el.group_name ?
                    <Marker
                        key={el.quote}
                        position={[el.geo_latitude, el.geo_longitude]}
                        eventHandlers={{
                            click: () => {
                                console.log("marker clicked", el);
                                console.log(map.getZoom());
                            }
                        }
                        }
                    >
                        <Popup>
                            Lead located near {el.geo_city}
                        </Popup>
                    </Marker>
                    :
                    <></>
                }
            </>

        ));
    };


    return (<>
        <Container fluid>
            <Row className="mt-4 mb-1">
                <Col>
                    <h5>Leads <small className="text-muted">interactive map</small></h5>
                </Col>
            </Row>
            <Row className="mb-1 pb-1">

                <Col>
                    {ready && items.length > 0 ?

                        <MapContainer className="map-container" center={[54.063723, -101.810794]} zoom={4} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[49.891424, -97.1472437]}>
                                <Popup>
                                    Wawanesa new headquarters
                                </Popup>
                            </Marker>
                            <CustomMarkers />

                        </MapContainer>
                        :
                        <><div className='dot-pulse'></div></>
                    }
                </Col>
            </Row>
            <Row className="mb-4 pb-4">
                <Col>
                    <small className="text-muted">Location is based on internet access point and may not reflect the actual residence.</small>
                </Col>
            </Row>
        </Container>
    </>)
}
