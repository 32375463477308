import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Col,
    Container,
    Image,
    Navbar,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';
import { VscColorMode } from 'react-icons/vsc';
import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import logo from '../bootstrap/logo.png';
import * as config from '../config.js';
import { fetchTokens } from '../libs/auth';
import * as contexts from '../libs/contexts';
import { LayoutContext } from '../libs/contexts';
import { Forbidden } from './forbidden';
import {
    LoginPage,
    RedirectPage,
} from './login';
import { AppSession } from './main';

export const Page = ({ query, desktop }) => {

    const [downloads, setDownloads] = useState(false);
    const [leads, setLeads] = useState(false);
    const [reportingData, setReportingData] = useState(false);
    const [searchData, setSearchData] = useState(false);
    const [layout, setLayout] = useReducer(contexts.stateReducer, {});
    const [session, setSession] = useReducer(contexts.stateReducer, {});
    const [settings, setSettings] = useReducer(contexts.stateReducer, {});

    const [authorizationExpiration, setAuthorizationExpiration] = useState(0);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {


        const saveUrl = () => {
            if (!window.location.href.includes("redirect")) {
                let requestedUrl = window.location.pathname + window.location.search;
                console.info("Setting request", requestedUrl);
                localStorage.setItem("requested", requestedUrl);
            } else {
                console.info("Not setting request");
            }
        }
        console.info("Page hook");

        setReportingData({
            book: {},
        });

        let cached = localStorage.getItem("session");
        let requested = localStorage.getItem("requested");
        if (cached) {
            let sessionInfo = JSON.parse(cached);
            let isValid = false;
            if (sessionInfo && sessionInfo.email) {
                if (sessionInfo.exp && sessionInfo.exp > Date.now() / 1000) {
                    setSession({
                        email: sessionInfo.email,
                        token: sessionInfo.access_token,
                        auth: sessionInfo
                    });
                    setAuthorizationExpiration(sessionInfo.exp);
                    setIsAuthorized(true);
                    isValid = true;
                    if (requested) {
                        localStorage.removeItem("requested");
                        navigate(requested);
                    } else {
                        navigate("/");
                    }
                }
            }
            if (!isValid) {
                saveUrl();
            }
        } else {
            saveUrl();
        }

    }, []);


    useEffect(() => {
        if (0 === authorizationExpiration) {

            if (undefined !== query) {

                if (query.code) {
                    setShowMessage(false);
                    fetchTokens({
                        code: query.code,
                        redirect: config.appConfig.auth_redirect,
                        client: config.appConfig.BrokerPoolClientParameter,
                        endpoint: config.appConfig.auth_endpoint,
                        next: (sessionInfo) => {
                            let requested = localStorage.getItem("requested");

                            setSession({
                                email: sessionInfo.email,
                                token: sessionInfo.access_token,
                                auth: sessionInfo
                            });
                            setAuthorizationExpiration(sessionInfo.exp);
                            setIsAuthorized(true);
                            if (requested) {
                                localStorage.removeItem("requested");
                                navigate(requested);
                            } else {
                                navigate("/");
                            }
                        }
                    });
                } else {
                    setShowMessage(true);
                }
            }
        }
    }, [authorizationExpiration, query]);

    return (
        <>
            {isAuthorized ?

                <contexts.LayoutContext.Provider value={{ layout, setLayout }}>
                    <contexts.SessionContext.Provider value={{ session, setSession }}>
                        <contexts.LeadsContext.Provider value={{ leads, setLeads }}>
                            <contexts.ReportingDataContext.Provider value={{ reportingData, setReportingData }}>
                                <contexts.DownloadsContext.Provider value={{ downloads, setDownloads }}>
                                    <contexts.SettingsContext.Provider value={{ settings, setSettings }}>
                                        <contexts.SearchContext.Provider value={{ searchData, setSearchData }}>
                                            <>
                                                <AppSession query={query} desktop={desktop} />
                                            </>
                                        </contexts.SearchContext.Provider>
                                    </contexts.SettingsContext.Provider>
                                </contexts.DownloadsContext.Provider>
                            </contexts.ReportingDataContext.Provider>
                        </contexts.LeadsContext.Provider>
                    </contexts.SessionContext.Provider>
                </contexts.LayoutContext.Provider>

                :
                <>
                    {'/login' === location.pathname ?
                        <LoginPage showMessage={showMessage} />
                        :
                        <>
                            {'/redirect' === location.pathname ?
                                <RedirectPage showMessage={showMessage} />
                                :
                                <Forbidden showMessage={showMessage} />
                            }
                        </>
                    }

                </>
            }
        </>
    )


}



export const SecurePage = () => {

    const { layout } = useContext(LayoutContext);

    return (
        <>
            {layout.desktop ?
                <DesktopPage />
                :
                <MobilePage />
            }
        </>
    )

}

const DesktopPage = () => {
    return (<>
        <DesktopMenu />

        <Container fluid className="mb-4">
            <Row>
                <Col className="col-1 text-center mx-auto pl-4">
                    <Sidebar />
                </Col>
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    </>);
}

const MobilePage = () => {
    return (<>
        <MobileMenu />

        <Container fluid className="mb-4">
            <Row>
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    </>);
}

const DesktopMenu = () => {

    const { layout, setLayout } = useContext(LayoutContext);

    const swapTheme = () => {

        //let elem = document.querySelector('html');

        if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {
            document.documentElement.setAttribute('data-bs-theme', 'light')
        }
        else {
            document.documentElement.setAttribute('data-bs-theme', 'dark')
        }
    }


    return (
        <Navbar>
            <Container fluid className="text-center">
                <Image style={{ width: "140px" }} src={logo} />
                <Navbar.Brand className="text-danger" href="/">Control<span className="text-warning">Room</span></Navbar.Brand>
                <VscColorMode onClick={swapTheme} className="float-end clicker" title="Toggle dark/light mode" />
            </Container>
        </Navbar>
    )
}


const MobileMenu = () => {
    return (
        <Navbar className="bg-dark text-white">
            <Container fluid>
                <Navbar.Brand className="text-white" href="/">Link Control<span className="text-info">Room</span></Navbar.Brand>
                <Image className="float-end" style={{ height: "35px" }} src="controlroom/wawanesalife-wb.png" />
            </Container>
        </Navbar>
    )
}

const Sidebar = () => {

    const [screen, setScreen] = useState('dashboard');

    const navigate = useNavigate();
    let location = useLocation();


    useEffect(() => {

        if (location && location.pathname) {
            let screenName = location.pathname.split('/').pop();
            if (screenName && screenName.length > 2) {
                setScreen(screenName);
            } else {
                setScreen('dashboard');
            }
        }

    }, [location]);

    return (
        <>
            <h1 onClick={() => navigate('/')} className={"clicker mt-4 menu-" + ("dashboard" === screen ? "active text-info" : "inactive")}><BsStar /></h1>
            <h1 onClick={() => navigate('/reports')} className={"clicker mt-4 menu-" + ("reports" === screen ? "active text-info" : "inactive")}><BsBarChartLine /></h1>
            <h1 onClick={() => navigate('/leads')} className={"clicker mt-4 menu-" + ("leads" === screen ? "active text-info" : "inactive")}><BsCartCheck /></h1>
            <h1 onClick={() => navigate('/downloads')} className={"clicker mt-4 menu-" + ("downloads" === screen ? "active text-info" : "inactive")}><BsCloudDownload /></h1>
            <h1 onClick={() => navigate('/settings')} className={"clicker mt-4 menu-" + ("settings" === screen ? "active text-info" : "inactive")}><BsGearFill /></h1>
        </>
    );

}