import 'echarts-gl';

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import * as echarts from 'echarts';
//import { init } from 'echarts';
import {
  Alert,
  Badge,
  Card,
  CardGroup,
  Col,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  Row,
} from 'react-bootstrap';

export const Province = () => {


    const [items, setItems] = useState([]);
    const [ready, setReady] = useState(false);
    const [conversionRate, setConversionRate] = useState(false);
    const [mobileRate, setMobileRate] = useState(false);
    const [genderRate, setGenderRate] = useState(false);
    const [totals, setTotals] = useState(false);

    useEffect(() => {


    }, []);


    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <GeoChart />
                    </Col>
                </Row>

            </Container>
        </>
    );
}


export const GeoChart = () => {


    const [items, setItems] = useState([]);
    const [ready, setReady] = useState(false);
    const [chartOptions, setChartOptions] = useState({});


    useEffect(() => {



        let newItems = [
            {
                week_date: '2023-10-18',
                geo_province: 'MB',
            },
            {
                week_date: '2023-10-18',
                geo_province: 'MB',
            },
            {
                week_date: '2023-10-18',
                geo_province: 'MB',
            },
            {
                week_date: '2023-10-11',
                geo_province: 'MB',
            },
            {
                week_date: '2023-10-11',
                geo_province: 'MB',
            },
            {
                week_date: '2023-10-04',
                geo_province: 'MB',
            },
            {
                week_date: '2023-10-18',
                geo_province: 'AB',
            },
            {
                week_date: '2023-10-18',
                geo_province: 'AB',
            },
            {
                week_date: '2023-10-11',
                geo_province: 'AB',
            },
            {
                week_date: '2023-10-11',
                geo_province: 'AB',
            },
            {
                week_date: '2023-10-11',
                geo_province: 'AB',
            },
            {
                week_date: '2023-10-04',
                geo_province: 'AB',
            }
        ];
        setItems(newItems);
        setReady(true);



    }, []);


    return (
        <>
            <Container fluid>
                {/* <Row className='mb-3'>
                    <Col className="text-center">
                        <h1>Leads by province, by week</h1>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        {ready ?
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <ProvinceChart rows={items} />
                                    </Col>

                                </Row>
                            </Container>

                            :
                            <div style={{ width: "100%", height: "600px" }}>
                                <div className='dot-pulse'></div>
                            </div>
                        }

                    </Col>
                </Row>
            </Container>
        </>
    );
}



const ProvinceChart = ({ rows }) => {

    const [containerId, setContainerId] = useState('geotime');
    const [items, setItems] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [chart, setChart] = useState({});

    useEffect(() => {

        let myChart = echarts.init(document.getElementById(containerId));
        setChart(myChart);

    }, []);

    useEffect(() => {

        if (rows && rows.length > 0) {

            let newWeeks = [];
            let newProvinces = [];
            let newItems = [];

            for (let row of rows) {

                let parts = row.week_date.split('-');
                let newDate;
                if (3 === parts.length) {
                    newDate = [parts[1], parts[2]].join('/');
                } else {
                    newDate = row.week_date;
                }

                if (!newWeeks.includes(newDate)) {
                    newWeeks.push(newDate)
                }
                if (!newProvinces.includes(row.geo_province)) {
                    newProvinces.push(row.geo_province)
                }
                let newItem = { ...row };
                newItem.week_date = newDate;
                newItems.push(newItem);
            }

            newWeeks.sort();
            newProvinces.sort();

            setWeeks(newWeeks);
            setProvinces(newProvinces);
            setItems(newItems);

        }

    }, [rows]);

    useEffect(() => {



        if (items && items.length > 0 && weeks && weeks.length > 0 && provinces && provinces.length > 0) {

            console.log("WEEKS", weeks);


            let yData = {};

            for (let i = 0; i < provinces.length; i++) {
                yData[provinces[i]] = {
                    pos: i
                };
            }

            let xData = {}

            for (let i = 0; i < weeks.length; i++) {
                xData[weeks[i]] = {
                    pos: i
                };
            }

            let zData = [];
            for (let item of items) {
                let provincePos = yData[item.geo_province].pos;
                let weekPos = xData[item.week_date].pos;
                let value = item.leads
                let datapoint = [provincePos, weekPos, value];
                zData.push(datapoint);
            }

            console.info("X", xData);
            console.info("Y", yData);
            console.info("Z", zData);

            const labelFormat = (item) => {
                let result = "";
                let province = item.data.value[1];
                let week = item.data.value[2];
                for (let [k, v] of Object.entries(xData)) {
                    if (v.pos === week) {
                        result = k + " ";
                    }
                }
                for (let [k, v] of Object.entries(yData)) {
                    if (v.pos === province) {
                        result = result + k + ": ";
                    }
                }
                result = result + "" + item.data.value[0];
                return result;
            }

            const tooltipFormat = (item) => {
                let result = "";
                let province = item.data.value[1];
                let week = item.data.value[2];
                for (let [k, v] of Object.entries(xData)) {
                    if (v.pos === week) {
                        result = k + " ";
                    }
                }
                for (let [k, v] of Object.entries(yData)) {
                    if (v.pos === province) {
                        result = result + k + ": ";
                    }
                }
                result = result + "" + item.data.value[0];
                return result;
            }

            // prettier-ignore
            var hours = ['12a', '1a', '2a', '3a', '4a', '5a', '6a',
                '7a', '8a', '9a', '10a', '11a',
                '12p', '1p', '2p', '3p', '4p', '5p',
                '6p', '7p', '8p', '9p', '10p', '11p'];
            // prettier-ignore
            var days = ['Saturday', 'Friday', 'Thursday',
                'Wednesday', 'Tuesday', 'Monday', 'Sunday'];
            // prettier-ignore y,x,z  -- province, week, leads
            var data = [[0, 0, 5], [0, 1, 1], [0, 2, 0], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5], [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0], [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8], [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2], [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0], [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]];
            let option = {
                title: {
                    show: false
                },
                tooltip: {
                    formatter: tooltipFormat
                },
                visualMap: {
                    max: 20,
                    inRange: {
                        color: [
                            '#313695',
                            '#4575b4',
                            '#74add1',
                            '#abd9e9',
                            '#e0f3f8',
                            '#ffffbf',
                            '#fee090',
                            '#fdae61',
                            '#f46d43',
                            '#d73027',
                            '#a50026'
                        ]
                    }
                },
                xAxis3D: {
                    type: 'category',
                    data: weeks,
                    name: ''
                    //data: hours
                },
                yAxis3D: {
                    type: 'category',
                    data: provinces,
                    name: '',
                    //data: days
                },
                zAxis3D: {
                    type: 'value',
                    name: ''
                },
                grid3D: {
                    boxWidth: 200,
                    boxDepth: 80,
                    viewControl: {
                        // projection: 'orthographic'
                    },
                    light: {
                        main: {
                            intensity: 1.2,
                            shadow: true
                        },
                        ambient: {
                            intensity: 0.3
                        }
                    }
                },
                series: [
                    {
                        type: 'bar3D',
                        name: 'leads',
                        bevelSize: 0.5,
                        bevelSmoothness: 2,
                        data: zData.map(function (item) {
                            return {
                                value: [item[1], item[0], item[2]]
                            };
                        }),
                        shading: 'lambert',
                        label: {
                            fontSize: 16,
                            borderWidth: 1,
                            distance: 15
                        },
                        itemStyle: {
                            opacity: 0.85
                        },
                        emphasis: {
                            label: {
                                fontSize: 20,
                                color: '#900',
                                formatter: ''
                            },
                            itemStyle: {
                                color: '#900'
                            }
                        }
                    }
                ]
            };


            chart.setOption(option);
        }

    }, [items, weeks, provinces]);

    useEffect(() => {
        let myChart = echarts.init(document.getElementById(containerId));
        setChart(myChart);
    }, []);

    return (
        <>
            <Container fluid className="mt-4 mb-2">
                <Row>
                    <Col>
                        <h5>Leads <small className='text-muted'>by province, by week</small></h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card bg="white">
                            <Card.Body className="pt-0">
                                <div className="pt-0" id={containerId} style={{ width: "100%", height: "600px" }}></div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-muted">
                        <small className='text-muted'>Rotate: right mouse button. Zoom: mouse wheel.</small>
                    </Col>
                </Row>
            </Container>

        </>
    )
}
