import * as React from 'react';

import * as ReactDOM from 'react-dom/client';

import { ControlRoom } from './app/main.jsx';

// we allow for some special runtime conditions via the query string or local storage
let params = new URLSearchParams(window.location.search);
let entries = params.entries();
let query = Object.fromEntries(entries);
//query.colorScheme = "dark" === localStorage.getItem("scheme") ? "dark" : "light";
//console.info("Scheme", query);

// deal wih hdpi
// console.info("Loading ratio calc", window.devicePixelRatio);
// let scale = 1 / (window.devicePixelRatio || 1);
// if (1 !== scale) {
//     let content = 'width=device-width, initial-scale=1.5';// + scale;
//     document.querySelector('meta[name="viewport"]').setAttribute('content', content);
//     console.info(document.querySelector('meta[name="viewport"]'));
// }

// check for mobile
let isDesktop = !(window.matchMedia("(max-width: 480px)").matches);


// document.onreadystatechange = function () {
//     var state = document.readyState;
//     if ('complete' === state) {



//     }
// };


ReactDOM.createRoot(document.getElementById("app")).render(
    <ControlRoom query={query} desktop={isDesktop} />
);


