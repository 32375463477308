import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';

import {
    LayoutContext,
    ReportingDataContext,
} from '../libs/contexts';

export const HistoryChartCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);
    const { reportingData } = useContext(ReportingDataContext);
    const [reportData, setReportData] = useState(false);
    const [chartOptions, setChartOptions] = useState({});
    const [ready, setReady] = useState(false);

    useEffect(() => {


        if (undefined !== reportingData && undefined !== reportingData.running) {

            setReportData(reportingData.running);

        }


    }, [reportingData])

    useEffect(() => {

        if (reportData) {
            let seriesList = [];

            //for (let row of reportData) {
            seriesList.push({
                type: 'line',
                datasetId: 'running',
                showSymbol: false,
                name: "leads",
                endLabel: {
                    show: false
                },
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                emphasis: {
                    focus: 'series'
                },
                encode: {
                    x: 'week_date',
                    y: 'total',
                    label: ['Week', 'Leads'],
                    itemName: 'Week',
                    tooltip: ['Lead']
                }
            });
            //}

            let options = {
                animationDuration: 3000,
                dataset: [
                    {
                        id: 'running',
                        source: reportData
                    }
                ],
                title: {
                    text: 'Running total'
                },
                tooltip: {
                    order: 'valueDesc',
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'week',
                    nameLocation: 'middle',
                    min: "2023-01-04",
                    min: "2024-01-04"
                },
                yAxis: {
                    name: 'leads',
                    min: 0,
                    max: 50
                },
                grid: {
                    right: 140
                },
                series: seriesList
            };
            setChartOptions(options);
            setReady(true);
        }


    }, [reportData]);

    return (

        <Card>
            <Card.Header>
                Leads over time <small className='float-end text-muted'>cumulative</small>
            </Card.Header>
            <Card.Body>
                {ready ?
                    <RunningChart rows={reportData} />
                    :
                    <>...</>
                }
            </Card.Body>
            {'full' === footer &&
                <Card.Footer>
                    <Button variant="outline-primary">More charts</Button>
                </Card.Footer>
            }
        </Card>

    )

}

const RunningChart = ({ rows }) => {

    const [containerId, setContainerId] = useState('runningchart');
    const [items, setItems] = useState([]);
    const [chart, setChart] = useState({});

    useEffect(() => {

        if (items && items.length > 0) {

            let option = {
                animationDuration: 1200,
                dataset: [
                    {
                        id: 'running',
                        source: rows
                    }
                ],
                xAxis: {
                    type: 'category',
                    nameLocation: 'middle'
                },
                yAxis: {
                    name: ''
                },
                series: items
            };

            chart.setOption(option);

        }

    }, [rows, items]);

    useEffect(() => {

        if (rows && rows.length > 0) {

            let newItems = [];

            for (let row of rows) {
                newItems.push({
                    type: 'line',
                    datasetId: 'running',
                    showSymbol: false,
                    name: "leads",
                    endLabel: {
                        show: false
                    },
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    encode: {
                        x: 'week_date',
                        y: 'total',
                        label: ['Week', 'Leads'],
                        itemName: 'Week',
                        tooltip: ['Lead']
                    }
                });
            }

            setItems(newItems);

        }

    }, [rows]);

    useEffect(() => {

        let myChart = init(document.getElementById(containerId));
        setChart(myChart);

    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>

                        <div id={containerId} style={{ width: "100%", height: "400px" }}></div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}


