import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsFileEarmarkExcel,
    BsFiletypeCsv,
    BsFillPinMapFill,
    BsGearFill,
    BsGenderFemale,
    BsStar,
    BsUmbrella,
} from 'react-icons/bs';
import { MdOutlineAddIcCall } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
    LayoutContext,
    ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

export const BasicInsightsCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);
    const { reportingData } = useContext(ReportingDataContext);
    const [insights, setInsights] = useState(false);

    useEffect(() => {
        if (undefined !== reportingData && undefined !== reportingData.hits) {

            let leads = 0;
            let visitors = 0;
            for (let hit of reportingData.hits) {
                if (60 === hit.days) {
                    switch (hit.category) {
                        case "leads":
                            leads = hit.value;
                            break;
                        case "visitors":
                            visitors = hit.value;
                            break;
                        default:
                            break;
                    }

                }

            }
            let total = leads + visitors;
            if (total > 0) {
                let conversion = Math.round(100 * (leads / total));

                setInsights(conversion + "% of visitors provided contact information");
            } else {
                setInsights("(Not enough data)");
            }

        }
    }, [reportingData]);

    return (



        <Card>
            <Card.Header>
                Data insights
            </Card.Header>
            <Card.Body>
                {insights ?
                    <>{insights}</>
                    :
                    <>...</>
                }
            </Card.Body>
            {'full' === footer &&
                <Card.Footer>
                    <Link to="/reports"><Button variant="outline-primary">More insights</Button></Link>
                </Card.Footer>
            }
        </Card>



    )

}

export const InsightsTable = () => {

    const { layout } = useContext(LayoutContext);

    const { reportingData } = useContext(ReportingDataContext);
    const [insights, setInsights] = useState(false);

    useEffect(() => {
        if (undefined !== reportingData) {

            let newInsights = {};
            if (undefined !== reportingData.hits) {
                newInsights.converson = 0;
                let leads = 0;
                let visitors = 0;
                for (let hit of reportingData.hits) {
                    if (60 === hit.days) {
                        switch (hit.category) {
                            case "leads":
                                leads = hit.value;
                                break;
                            case "visitors":
                                visitors = hit.value;
                                break;
                            default:
                                break;
                        }

                    }

                }
                let total = leads + visitors;
                if (total > 0) {
                    let conversion = Math.round(100 * (leads / total));
                    newInsights.conversion = conversion;
                }
            }

            if (undefined !== reportingData.latest) {
                let cities = {};
                let genders = { F: 1, M: 1 };
                let values = [];

                for (let lead of reportingData.latest) {
                    if (undefined === cities[lead.city]) {
                        cities[lead.city] = 0;
                    }
                    cities[lead.city]++;
                    if (undefined === genders[lead.gender]) {
                        genders[lead.gender] = 0;
                    }
                    genders[lead.gender]++;
                    values.push(lead.facevalue);
                }

                const sum = values.reduce((a, b) => a + b, 0);
                newInsights.facevalue = (sum / values.length) || 0;
                newInsights.facevalue = asCurrency({ amount: newInsights.facevalue, dropPennies: true });
                let cityCount = 0;
                for (let [k, v] of Object.entries(cities)) {
                    if (v > cityCount) {
                        cityCount = v;
                        newInsights.city = k;
                    }
                }

                newInsights.gender = Math.round(100 * (genders.F / (genders.F + genders.M)));
                setInsights(newInsights);
            }




        }
    }, [reportingData]);


    return (

        <Card>
            <Card.Header>
                Data insights
            </Card.Header>
            <Card.Body>
                {insights &&
                    <ListGroup variant="flush">
                        <ListGroup.Item>{insights.gender}% of your leads are female <span className="float-end text-muted"><BsGenderFemale /></span></ListGroup.Item>
                        <ListGroup.Item>{insights.city} is your most active city <span className="float-end text-muted"><BsFillPinMapFill /></span></ListGroup.Item>
                        <ListGroup.Item>Average face value requested is {insights.facevalue} <span className="float-end text-muted"><BsUmbrella /></span></ListGroup.Item>
                        <ListGroup.Item>{insights.conversion}% of your visitors left contact information<span className="float-end text-muted"><MdOutlineAddIcCall /></span></ListGroup.Item>
                    </ListGroup>
                }

            </Card.Body>
        </Card>



    )

}