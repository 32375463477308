import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BiImageAdd,
    BiLinkExternal,
    BiSupport,
    BiUserCheck,
} from 'react-icons/bi';
import {
    BsCardText,
    BsChatHeart,
    BsCheckSquare,
    BsGoogle,
    BsKey,
    BsMegaphone,
    BsSortDown,
    BsYoutube,
} from 'react-icons/bs';
import { TbArrowFork } from 'react-icons/tb';

import { ActionCardGroup } from '../controls/cards';
import { Hero } from '../controls/hero';
import {
    LayoutContext,
    SettingsContext,
} from '../libs/contexts';
import { BasicViewer } from './config-viewer';

const TemporaryDisabled = () => {

    return (
        <>
            <div className="text-center">
                <Badge>not available in demo</Badge>
            </div>
        </>
    )
}

export const Settings = () => {

    const { layout } = useContext(LayoutContext);
    const { settings, setSettings } = useContext(SettingsContext);

    const sections = useMemo(() => {

        const getFooterValue = (obj) => {

            let message = "No value yet";

            if (undefined !== obj && obj) {

                message = Object.keys(obj).length.toString() + " value";
                if (Object.keys(obj).length > 1) {
                    message += "s";
                }

            }

            return message;

        }

        return {
            customization: {
                headline: "Customization & branding",
                cards: {
                    contact: {
                        title: "Contact information",
                        text: "Provide contact information to be displayed to visitors.",
                        icon: <BiSupport />,
                        callback: "/contact",
                        label: "Manage",
                        footer: getFooterValue(settings.contact)
                    },
                    // assets: {
                    //     title: "Branding",
                    //     text: "Provide branding assets, such as your logo.",
                    //     icon: <BiImageAdd />,
                    //     callback: "/assets",
                    //     label: "Manage",
                    //     footer: getFooterValue(settings.assets)
                    // },
                    links: {
                        title: "Important URLs",
                        text: "Provide important URLs, such as the URL of your privacy policy.",
                        icon: <BiLinkExternal />,
                        callback: "/urls",
                        label: "Manage",
                        footer: getFooterValue(settings.links)
                    },
                    video: {
                        title: "Introduction video",
                        text: "Provide an introduction video to be displayed in the sidebar.",
                        icon: <BsYoutube />,
                        callback: "/video",
                        label: "Manage",
                        footer: getFooterValue(settings.video)
                    },
                    labels: {
                        title: "Labels and messages",
                        text: "Provide customized labels and messages for the UI.",
                        icon: <BsCardText />,
                        callback: "/labels",
                        label: "Manage",
                        footer: getFooterValue(settings.labels)
                    },
                    happyPath: {
                        title: "Happy path",
                        text: "Change the sequence of screens in the questionnaire.",
                        icon: <BsSortDown />,
                        callback: "/happypath",
                        label: "Manage",
                        footer: getFooterValue(settings.video)
                    },
                    fields: {
                        title: "Mandatory fields",
                        text: "Indicate which fields are mandatory to reveal the quote",
                        icon: <BsCheckSquare />,
                        callback: "/fields",
                        label: "Manage",
                        footer: getFooterValue(settings.fields)
                    }
                }
            },
            integrations: {
                headline: "Integrations",
                cards: {
                    api: {
                        title: "Link API",
                        text: "Create API keys for the Link client API.",
                        icon: <BsKey />,
                        callback: "/apikeys",
                        label: "Manage",
                        footer: getFooterValue(settings.api)
                    },
                    google: {
                        title: "Analytics",
                        text: "Provide your custom tracking codes.",
                        icon: <BsGoogle />,
                        callback: "/analytics",
                        label: "Manage",
                        footer: getFooterValue(settings.analytics)
                    }
                }
            },
            system: {
                headline: "System",
                cards: {
                    api: {
                        title: "Brand names",
                        text: "View list of brand names.",
                        icon: <BsMegaphone />,
                        callback: "/brands",
                        label: "Manage",
                        footer: getFooterValue(settings.brands)
                    },
                    profiles: {
                        title: "Traffic profiles",
                        text: "Tweak A/B traffic ratio.",
                        icon: <TbArrowFork />,
                        callback: "/profiles",
                        label: "Manage",
                        footer: getFooterValue(settings.profiles)
                    },
                    users: {
                        title: "Users and notifications",
                        text: "Manage accounts and mailing lists.",
                        icon: <BiUserCheck />,
                        callback: "/users",
                        label: "Manage",
                        footer: getFooterValue(settings.profiles)
                    },
                    sales: {
                        title: "Sales tips",
                        text: "Manage sales tips and objection handling.",
                        icon: <BsChatHeart />,
                        callback: "/closing",
                        label: "Manage",
                        footer: getFooterValue(settings.sales)
                    }
                }
            }
        };
    }, [settings.asOf]);


    return (
        <>
            <Hero title="System settings" />
            <BasicViewer />
            {/* {Object.entries(sections).map(([sectionId, section]) => (
                <React.Fragment key={sectionId}>

                    <Hero title={section.headline} text={section.message} />

                    <ActionCardGroup groupDefinition={section.cards} />

                </React.Fragment>


            ))} */}

        </>
    );
}



