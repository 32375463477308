// config for controlroom
export const assets = "https://trn2-cmf-assets.link-nonprod.wawanesalife.com";
export const gateway = "https://cmf-controlroom-api.link-nonprod.wawanesalife.com";
export const brokerConfig = {
  "authorized_mb": "yes", "authorized_sk": "yes", "authorized_qc": "no", "authorized_nb": "yes", "authorized_ns": "yes","enabled": "yes", "authorized_on": "yes", "authorized_ab": "yes", "authorized_bc": "yes", "split": 0.5,
  "name": "cmf",
  "broker": "cmf",
  "display": "cmf",
  "topic": "arn:aws:sns:ca-central-1:965766005558:trn2-cmf-admin"
};
export const appConfig = {
      "BrokerPoolClientParameter": "1roftr02q6uo180jsloj93hsh9",
      "LoginUrlParameter": "https://trn2-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1roftr02q6uo180jsloj93hsh9&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fcmf-controlroom.link-nonprod.wawanesalife.com%2Fredirect",
      "BrokerUrlParameter": "https://cmf-controlroom.link-nonprod.wawanesalife.com",
      "AthenaDatabaseParameter": "trn2-cmf",
      "AppHostParameter": "cmf-controlroom",
      "AthenaTableParameter": "quotes",
      "ApiHostParameter": "cmf-controlroom-api",
      "DocumentsHostParameter": "cmf-controlroom-docs",
      "auth_endpoint": "https://trn2-brokers.auth.ca-central-1.amazoncognito.com",
      "OaiParameter": "origin-access-identity/cloudfront/E2WKAMVJV1HY25",
      "auth_redirect": "https://cmf-controlroom.link-nonprod.wawanesalife.com/redirect",
};
