import React, { useContext } from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    ListGroup,
    Row,
} from 'react-bootstrap';
import {
    Link,
    useNavigate,
} from 'react-router-dom';

import {
    LayoutContext,
    ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

export const FeaturedLeadsCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);
    const { reportingData } = useContext(ReportingDataContext);
    const navigate = useNavigate();

    const showQuote = (id) => {
        navigate('/leads/' + id);
    }

    return (


        <Card>
            <Card.Header>
                Close a sale today!
            </Card.Header>
            <Card.Body>

                {reportingData.latest &&
                    <>
                        <ListGroup variant="flush">
                            {reportingData.latest.map((lead, index) => (
                                <ListGroup.Item key={index} action onClick={() => showQuote(lead.quote)}><span className="text-warning">({asCurrency({ amount: lead.facevalue, dropPennies: true })})</span> {lead.first_name}, {lead.city} <span className="float-end text-muted">{lead.date}</span></ListGroup.Item>
                            ))}
                        </ListGroup>

                    </>}


            </Card.Body>

        </Card>



    )

}
