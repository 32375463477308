import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCalculator,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { getLead } from '../libs/api';
import { asCurrency } from '../libs/i18n';
import { convertOffers } from '../libs/utils';
import { QuickTermSimpleQuoter } from '../quoters/quickterm';
import { LeadFeatureDisabled } from './lead';
import {
  CostObjection,
  ProductObjection,
  TimingObjection,
  ValueObjection,
} from './objections';
import { SalesAdvisor } from './sales-tips';
import { Statistics } from './statistics';
import { Timeline } from './timeline';
import { UpsellCard } from './upsell';

export const AlternativeCard = ({ lead }) => {

  const [view, setView] = useState('default');



  return (
    <>
      {'default' === view ?
        <InfoCard
          header="Alternatives"
          headerClass="bg-warning text-white"
          subheader="Opportunities available"
          text={<Alternatives lead={lead} setView={setView} />}
          icon={<BsArrowLeftRight />}
        />
        :
        <>
          <InfoCard
            header="Instant quote"
            headerClass="bg-warning text-white"
            text={<QuickTermSimpleQuoter cancelButton={<Button onClick={() => setView('default')}><BsCalculator /> Cancel</Button>} />}
            icon={<BsCalculator />}
          />
        </>
      }

    </>
  )
}

const Alternatives = ({ lead, setView }) => {

  const [alternativesData, setAlternativesData] = useState(false);

  useEffect(() => {
    if (undefined !== lead.offers) {
      //todo refactor          
      let offers = convertOffers({ valueStr: lead.offers });
      let newData = {
        alternatives: false
      };
      if (10 === lead.term) {

        try {
          if (['yes', 'Yes'].includes(lead.rider)) {
            if (['no', 'No'].includes(lead.smoker)) {
              newData.noRider = offers.term10.nonsmoker.norider.monthly;
              newData.alternatives = true;
            } else {
              newData.noRider = offers.term10.smoker.norider.monthly;
              newData.alternatives = true;
            }
          }
        } catch (err) {
          console.log(err);
        }
      } else {

        try {
          newData.alternatives = true;
          newData.downgrade = true;

          if (['yes', 'Yes'].includes(lead.rider)) {

            if (['no', 'No'].includes(lead.smoker)) {
              newData.noRider = offers.term20.nonsmoker.norider.monthly;
            } else {
              newData.noRider = offers.term20.smoker.norider.monthly;
            }

          }

          if (['no', 'No'].includes(lead.smoker)) {

            newData.downgradeWithRider = offers.term10.nonsmoker.rider.monthly;
            newData.downgradeWithNoRider = offers.term10.nonsmoker.norider.monthly;
          } else {

            newData.downgradeWithRider = offers.term10.smoker.rider.monthly;
            newData.downgradeWithNoRider = offers.term10.smoker.norider.monthly;
          }

        } catch (err) {
          console.log(err);
        }

      }




      setAlternativesData(newData);

    }

  }, [lead]);


  return (
    <>
      <ListGroup variant="flush">
        {alternativesData &&
          <>
            {alternativesData.downgrade ?
              <>
                <ListGroup.Item>10 year term + rider ({asCurrency({ amount: alternativesData.downgradeWithRider })} monthly)</ListGroup.Item>
                <ListGroup.Item>10 year term, no rider ({asCurrency({ amount: alternativesData.downgradeWithNoRider })} monthly)</ListGroup.Item>
                {alternativesData.noRider &&
                  <ListGroup.Item>Remove rider ({asCurrency({ amount: alternativesData.noRider })} monthly)</ListGroup.Item>
                }
              </> :
              <>
                {alternativesData.alternatives ?
                  <ListGroup.Item>Remove rider ({asCurrency({ amount: alternativesData.noRider })} monthly)</ListGroup.Item>
                  :
                  <Badge>no obvious alternatives</Badge>
                }
              </>}
          </>
        }
      </ListGroup>
    </>
  )

}