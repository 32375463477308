import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import { BiDollarCircle } from 'react-icons/bi';
import {
    BsBarChartLine,
    BsCartCheck,
    BsClockHistory,
    BsCloudDownload,
    BsFileEarmarkExcel,
    BsFiletypeCsv,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';
import {
    FaRunning,
    FaSearchPlus,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BasicCard } from '../controls/cards';
import {
    LayoutContext,
    ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

const TemporaryDisabled = () => {

    return (
        <>
            <div className="text-center">
                <Badge>not available in demo</Badge>
            </div>
        </>
    )
}

export const MostRecent = () => {

    return (
        <BasicCard header="Most recent" body={<LeadList category="latest" />} icon={<BsClockHistory />} footer="Latest leads" />
    )

}

export const TopMotivated = () => {

    return (

        <BasicCard header="Most motivated" body={<LeadList category="motivated" />} icon={<FaRunning />} footer="Longest sessions" />

    )

}


export const TopFacevalue = () => {


    return (
        <BasicCard header="Highest face value" body={<LeadList category="facevalue" />} icon={<BiDollarCircle />} footer="Leads with the highest face value" />
    )

}

export const CustomSearch = ({ footer }) => {


    return (
        <BasicCard header="Search" body={<TemporaryDisabled />} icon={<FaSearchPlus />} footer="Free-form search" />
    )

}

const LeadList = ({ category }) => {


    const { reportingData } = useContext(ReportingDataContext);
    const navigate = useNavigate();

    const showQuote = (id) => {
        navigate('/leads/' + id);
    }



    return (
        <>
            {reportingData.latest &&
                <>
                    <ListGroup variant="flush">
                        {reportingData[category].map((lead, index) => (
                            <ListGroup.Item key={index} action onClick={() => showQuote(lead.quote)}><span className="text-warning">({asCurrency({ amount: lead.facevalue, dropPennies: true })})</span> {lead.first_name}, {lead.city} <span className="float-end text-muted">{lead.date}</span></ListGroup.Item>
                        ))}
                    </ListGroup>

                </>}


        </>
    );

}

