import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Badge,
    Button,
    Card,
    CardGroup,
    Col,
    Container,
    Form,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BiImageAdd,
    BiLinkExternal,
    BiSupport,
    BiUserCheck,
} from 'react-icons/bi';
import {
    BsCardText,
    BsChatHeart,
    BsCheckSquare,
    BsGoogle,
    BsKey,
    BsMegaphone,
    BsSortDown,
    BsYoutube,
} from 'react-icons/bs';
import { TbArrowFork } from 'react-icons/tb';

import { ActionCardGroup } from '../controls/cards';
import { Hero } from '../controls/hero';
import {
    LayoutContext,
    ReportingDataContext,
    SettingsContext,
} from '../libs/contexts';

export const BasicViewer = () => {

    const { reportingData } = useContext(ReportingDataContext);
    const [viewItems, setViewItems] = useState(false);

    useEffect(() => {
        if (reportingData) {
            let newItems = {
                integrations: {},
                branding: {},
                subscriptions: []
            };
            if (reportingData.broker) {


                for (let item of reportingData.broker) {
                    switch (item.id) {
                        case 'analytics':
                            newItems.integrations['Google Analytics'] = item.ga_measurement;
                            newItems.integrations['Stream'] = item.ga_stream;
                            newItems.integrations['Google Tag Manager'] = item.gtm;
                            break;
                        case 'branding#a':
                            newItems.branding['Video'] = item.video;
                            break;
                        case 'customization':
                            newItems.integrations['API Key'] = item.api_key;
                            newItems.integrations['API URL'] = item.bridge_url;
                            newItems.integrations['Redirect URL'] = item.api_redirect;
                            newItems.branding['URL FTC'] = item.url_ftc;
                            newItems.branding['URL Accessibility'] = item.url_accessibility;
                            newItems.branding['URL Privacy'] = item.url_privacy;
                            newItems.subscriptions = item.subscriptions;
                            break;
                        default:
                            break;
                    }
                }

                setViewItems(newItems);

            }
        }
    }, [reportingData]);

    useEffect(() => {
        if (viewItems) {
            console.info("Items", viewItems);
        }
    }, [viewItems]);

    return (
        <>
            <Container>
                {viewItems && viewItems.integrations &&
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Header>Integrations</Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>API Key: {viewItems.integrations['API Key']}</ListGroup.Item>
                                        <ListGroup.Item>API URL: {viewItems.integrations['API URL']}</ListGroup.Item>
                                        <ListGroup.Item>Redirect URL: {viewItems.integrations['Redirect URL']}</ListGroup.Item>
                                        <ListGroup.Item>Google Analytics: {viewItems.integrations['Google Analytics']}</ListGroup.Item>
                                        <ListGroup.Item>Stream: {viewItems.integrations['Stream']}</ListGroup.Item>
                                        <ListGroup.Item>Google Tag Manager: {viewItems.integrations['Google Tag Manager']}</ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {viewItems && viewItems.branding &&
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Header>Branding</Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Introduction Video: {viewItems.branding['Video']}</ListGroup.Item>
                                        <ListGroup.Item>URL - Privacy: {viewItems.branding['URL Privacy']}</ListGroup.Item>
                                        <ListGroup.Item>URL - Accessibility: {viewItems.branding['URL Accessibility']}</ListGroup.Item>
                                        <ListGroup.Item>URL - FTC: {viewItems.branding['URL FTC']}</ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {viewItems && viewItems.subscriptions &&
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Header>Subscriptions</Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        {viewItems.subscriptions.map((subscription) => (
                                            <ListGroup.Item key={subscription}>{subscription}</ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    )

}