import {
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Card,
    CardGroup,
    Col,
    Container,
    ListGroup,
    Row,
    Tab,
    Tabs,
} from 'react-bootstrap';
import {
    BiChurch,
    BiSolidCreditCardAlt,
    BiSolidPhoneCall,
} from 'react-icons/bi';
import {
    BsArrowLeftRight,
    BsCaretRight,
    BsCartPlus,
    BsEnvelopeAt,
    BsHouseHeart,
} from 'react-icons/bs';
import {
    FaBabyCarriage,
    FaBalanceScale,
    FaCar,
    FaHandsHelping,
} from 'react-icons/fa';
import {
    MdRefresh,
    MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { getLead } from '../libs/api';
import { asCurrency } from '../libs/i18n';
import { Statistics } from './statistics';
import { Timeline } from './timeline';

const TalkingPoints = ({ points }) => {

    return (
        <>
            {points &&
                <ListGroup variant="flush">
                    {points.map((point, index) => (
                        <ListGroup.Item key={index}>{point}</ListGroup.Item>
                    ))}
                </ListGroup>
            }
        </>
    )
}


export const CostObjection = ({ lead }) => {

    const [talkingPoints, setTalkingPoints] = useState(false);

    useEffect(() => {

        if (undefined !== lead && undefined !== lead.premium) {
            let premiumValue = parseFloat(lead.premium);
            if (!isNaN(premiumValue)) {
                let comparables = [];
                let daily = premiumValue / 30.0;
                let weekly = daily * 7;
                comparables.push(asCurrency({ amount: daily }) + " per day, " + asCurrency({ amount: weekly }) + " weekly");
                if (daily < 1) {
                    comparables.push("Less expensive than a visit at the dollar store");
                } else {
                    comparables.push("Less expensive than a scratch ticket that didn't win");
                }
                let comparableWeekly = "Less expensive than a McDonald's meal per week";
                if (weekly < 10) {
                    if (weekly < 8) {
                        comparableWeekly = "One Starbucks grande iced coffee per week";
                    } else {
                        comparableWeekly = "One Starbucks venti mocha per week";
                    }
                }
                comparables.push(comparableWeekly);
                setTalkingPoints(comparables);
            }
        }
        // if (undefined !== lead) {
        //     setTalkingPoints(["$0.99 per day, $6.93 per week", "Less  than a visit at the dollar store", "One Starbucks venti mocha per week"]);
        // }

    }, [lead]);

    return (
        <TalkingPoints points={talkingPoints} />
    )
}

export const ProductObjection = ({ lead }) => {


    const [talkingPoints, setTalkingPoints] = useState(false);

    useEffect(() => {

        if (undefined !== lead) {
            setTalkingPoints([
                "A term insurance policy is less expensive and easier to renew than whole life insurance.",
                "Benefits are paid as a tax-free lump sum.",
                "Group insurance only lasts while you are part of the group."
            ]);
        }

    }, [lead]);

    return (
        <TalkingPoints points={talkingPoints} />
    )

}

export const TimingObjection = ({ lead }) => {

    const [talkingPoints, setTalkingPoints] = useState(false);

    useEffect(() => {

        if (undefined !== lead) {
            setTalkingPoints([
                "Don't wait - the premium for the same coverage next year would be around $39.22",
                "The peace of mind you are seeking could start today.",
                "If you develop medical conditions, lose your driver license or experience other hardships before buying a policy, you could lose your eligibility.",
                "If you don't expect big changes in your circumstances, waiting only delays your protection and makes it more expensive."
            ]);
        }

    }, [lead]);

    return (
        <TalkingPoints points={talkingPoints} />
    )

}

export const ValueObjection = ({ lead }) => {


    const [talkingPoints, setTalkingPoints] = useState(false);

    useEffect(() => {

        if (undefined !== lead) {
            setTalkingPoints([
                "Wawanesa has been in business since 1896 and is in financial good health.",
                "Wawanesa is a mutual, which means it's own by the policyholders themselves.",
                "Wawanesa has a $2 million annual community impact program called Wawanesa Climate Champions."
            ]);
        }

    }, [lead]);

    return (
        <TalkingPoints points={talkingPoints} />
    )

}


