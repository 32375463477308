import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {

    useEffect(() => {
        console.info("Login page loaded");
    }, []);


    return (
        <>
            ...
        </>
    );

}

export const RedirectPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, []);

    return (
        <>...</>
    );

}
