import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Card,
    CardGroup,
} from 'react-bootstrap';
import { FaBirthdayCake } from 'react-icons/fa';
import { ImManWoman } from 'react-icons/im';

import { ReportingDataContext } from '../libs/contexts';
import { LeadFeatureDisabled } from './lead';

export const Statistics = ({ lead }) => {

    const { reportsData } = useContext(ReportingDataContext);
    const [facts, setFacts] = useState(false);



    useEffect(() => {



        if (undefined !== lead.gender) {

            let factoids = {};

            if (undefined !== lead.facevalue) {

                if (undefined !== lead.gender) {

                    let segmentId = [lead.gender.toLowerCase()];

                    if (undefined !== lead.current_age) {

                        if (lead.current_age <= 29) {
                            segmentId.push(18);
                            segmentId.push(29);
                        } else {
                            if (lead.current_age <= 39) {
                                segmentId.push(30);
                                segmentId.push(39);
                            } else {
                                segmentId.push(40);
                                segmentId.push(50);
                            }
                        }

                    } else {
                        segmentId.push("all");
                    }

                    //if (undefined !== reportsData.book[segmentId.join('#')]) {
                    if (true) {
                        if (1 < lead.facevalue) {
                            factoids['Age group'] = {
                                label: "face value above average",
                                icon: <FaBirthdayCake />,
                                color: 'text-danger'
                            };
                        } else {
                            factoids['Age group'] = {
                                label: "face value below average",
                                icon: <FaBirthdayCake />,
                                color: 'text-secondary'
                            }
                        }

                    }
                    segmentId = [lead.gender.toLowerCase(), 'all'];
                    if (true) {
                        if (1 < lead.facevalue) {
                            factoids['Gender'] = {
                                label: "face value above average",
                                icon: <ImManWoman />,
                                color: 'text-danger'
                            };
                        } else {
                            factoids['Gender'] = {
                                label: "face value below average",
                                icon: <ImManWoman />,
                                color: 'text-secondary'
                            }
                        }

                    }

                }

                setFacts(factoids);

            }

            //console.info("data", lead, reportsData.book);

        }


    }, [reportsData, lead.facevalue, lead.age, lead.gender]);




    return (
        //<LeadFeatureDisabled />
        <CardGroup>
            {facts &&
                <>
                    {Object.entries(facts).map(([key, fact]) => (
                        <Card className="mt-4" key={key}>
                            <Card.Body className={"text-center " + fact.color}>
                                <h1>{fact.icon}</h1>
                                {key}: {fact.label}
                            </Card.Body>
                        </Card>
                    ))}
                </>
            }

        </CardGroup>

    )

}

